// _mixins.scss

// generate classes for font-sizes
@each $element, $size in $font-sizes {
    .text-size-#{$element} {
        font-size: $size;
    }
    .font-size-#{$element} {
        font-size: $size;
    }
}

@each $element, $name in $font-size-names {
    .text-#{$element} {
        font-size: $name;
    }
}

// generate classes for font-weights
@each $element, $weight in $font-weights {
    .text-weight-#{$element} {
        font-weight: $weight;
    }
}

// generate classes for buttons
@each $color, $hex in $colors {
    .btn-#{$color} {
        background-color: $hex;
    }
}

// generate outline classes for buttons
@each $color, $hex in $colors {
    .btn-ouline-#{$color} {
        border: 1px solid $hex;
    }
}
