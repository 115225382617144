$zindex-sticky: 1020;
$zindex-fixed: 1030;

.fixed-positions {
    position: fixed;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;
}

.fixed-top {
    @extend .fixed-positions;
    top: 0;
}

.fixed-bottom {
    @extend .fixed-positions;
    bottom: 0;
}

.sticky-top {
    @supports (position: sticky) {
        position: sticky;
        top: 0;
        z-index: $zindex-sticky;
    }
}

.alert {
    position: relative;
    padding: 0.75rem 0.75rem;
    margin-bottom: 0.5rem;
    border: 1px solid transparent;
    &-error {
        background-color: rgba(190, 24, 0, 0.7);
        border-color: #be005e;
        color: #fff;
        z-index: 1;
        margin: 0;
        border-radius: 0;
    }
    &-warning {
        background-color: rgba(255, 122, 0, 0.7);
        border-color: #ff7d06;
        color: #fff;
        z-index: 1;
        margin: 0;
        border-radius: 0;
    }
}

.alert-dismissible {
    padding-right: 4rem;

    .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 0.75rem;
        color: inherit;
    }
}

.close {
    color: #fff;
    font-size: 2.0rem;
    font-weight: 700;
    line-height: unset;
    text-shadow: none;
}
