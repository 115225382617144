// app.scss

// https://useiconic.com/open
$icon-font-path: '~open-iconic/font/fonts/';
@import "~open-iconic/font/css/open-iconic-bootstrap.scss";

// http://flag-icon-css.lip.is
// @see also: https://www.flag-sprites.com for customizable sprites
$flag-icon-css-path: '~flag-icon-css/flags' !default;
@import "~flag-icon-css/sass/flag-icon";

/* -------begin bootstrap customization-------- */
@import "frontend/custom";

// import icomoon icons
//@import "../fonts/icomoon";
/* -------end bootstrap customization---------- */

// components for all, mostly sites
@import "frontend/components/breadcrump";
@import "frontend/components/alert";

// rotate search icon 90 degrees
.oi.oi-magnifying-glass {
    top: 2px;
    left: -2px;
    transform: rotate(90deg);
}

// always smaller font-size on list items
.list-inline-item {
    font-size: $font-size-sm;
}

// allow link always to be around parent cause of ideal behaviour
.link-block {
    display: block;
}

// no border on buttons and always rounded borders
.btn {
    padding: 0.5rem;
    border-radius: 8px;
    border: 0;
}

// added more btn with down arrow
.btn-more {
    padding: 0.5rem;
    border-radius: 0;
    background: $grey;
}

// added more btn with rounded borders
.btn-more-rounded {
    padding: 0.5rem;
    border-radius: $border-radius;
    background: $grey;
}

// added download buttons for sidebar
.download-button {
    //width: 85px;
}
.download-button__body {
    text-align: center;
    background-color: #a6ce17;
    display: block;
    padding: 10px;
    font-size: 24px;
}
.download-button__footer {
    display: block;
    background: #eee;
    padding: 5px;
    font-size: 11px;
    font-weight: 600;
}

// bootstrap navbar customizations
#nojs-label {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 8px;
    height: 50px;
}

// override default padding cause of problems with hover on menu items
.navbar {
    padding: 0;
}

// dont show checkbox input cause of no js hackery
#navbar-toggle-nojs {
    display:none
}
#navbar-toggle-nojs:checked ~ .collapse {
    display: block;
}

// adjust font-size on menu toggle button
.navbar-toggler-text {
    font-size: $font-size-md;
    vertical-align: sub;
    padding: 1rem 0.7rem 1rem 0.7rem;
}

.dropdown-menu {
    border-radius: 0;
    padding: 0;
    margin: 6px 0 0;
    left: unset;
}
.dropdown-menu:first-child {
    border-top: 1px solid $light-grey;
}
.dropdown-menu:last-child {
    border-bottom: 0;
}
.dropdown-item {
    padding: 1rem 2.5rem 1rem 1rem;
    border-bottom: 1px solid $light-grey;
}
.dropdown-submenu {
    position: relative;
    border: 0;
}
.dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
    margin-left: -1px;
}

// add shadow to body and maxwidth to application
.app-body {
    // max-width for whole application
    max-width: 1280px;
    box-shadow: 1px 1px 10px 0 rgba(51, 51, 51, 0.50);
}

// loader class for darken background of spinner
.loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(0,0,0, 0.5);
}

// spinner class
.spinner {
    position: fixed;
    z-index: 3;
    left: 50%;
    top: 50%;
    color: #000;
}

// hide element class
.hide {
    display: none;
}

// desktop customizations
@include media-breakpoint-up(sm) {

    // apply header height cause of corporate design study
    .app-header > .container-fluid {
        // height: 146px;
    }

    // make image in header smaller
    .app-header .container-fluid .img-fluid {
        // height: 87px;
        margin: 10px;
        height: 60px;
    }

    // show text underline on nav-link hover
    .navbar .nav-link {
        padding: 1rem;

        &:hover {
            text-decoration: underline;
        }
    }

    // fixes 1px space offset between dropdown-menu and submenu
    .dropdown-menu {
        border: 0;
        border-top: 1px solid $light-grey;
    }
    // show menu on hover instead of click
    .dropdown:hover > .dropdown-menu {
        display: block;
        // default
        // top: 80%;
        // adjust first navbar ul top and left
        // top: 96%;
        top: 89%; // adjusted after .navbar padding: 0
        left: -8%;
    }

    // show submenu on hover instead of click
    .dropdown-submenu:hover > .dropdown-menu {
        display: block;
    }

    // hide dropdown arrow on navbar
    .navbar .dropdown-toggle::after {
        display: none;
    }

    // hide dropdown arrow on navbar submenus
    .navbar .dropdown-submenu .dropdown-toggle::after {
        display: none;
    }

    // show submenu arrow on right
    .dropdown-submenu:after {
        position: absolute;
        top: 0;
        right: 0;
        padding: 4px;
        margin: 0;
        border: 0;
        font-size: 2rem;
        font-weight: 100;
        content: "\003e";
        color: #a6ce17;
        cursor: pointer;
        z-index: 3;
    }
    .dropdown-submenu:hover:after {
        color: white;
        background-color: $secondary;
    }

    // prevents from extending or shrinking while hovering cause of invisible given space
    .dropdown-submenu .dropdown-menu .dropdown-item {
        border-right: 5px solid transparent;
    }

    // show border-right on submenu items on right
    .dropdown-submenu .dropdown-menu .dropdown-item:hover {
        border-right: 5px solid $secondary;
    }
}

// between mobile and desktop
@include media-breakpoint-down(sm) {
    // no fixed header size between mobile and desktop
    .app-header > .container-fluid {
        height: unset;
    }
}

// mobile customizations
@include media-breakpoint-down(xs) {

    // navbar custom buttons for mobile
    .navbar-btn {
        border: 0;
        border-radius: 8px;
        // padding: 0.75rem;
        font-size: 1.5rem;
        text-align: center;
        &:hover {
            cursor:pointer;
            text-decoration: none;
        }
    }

    // show border-bottom on nav-items
    .navbar .nav-item {
        border-bottom: 1px solid $light-grey;
    }

    // show no border arround menu
    .dropdown-menu {
        border: 0;
    }

    // TODO: move to markup?
    .dropdown-menu .dropdown-item {
        padding: 1rem;
    }

    // show active state like on dropdown on focus, active
    // .nav-link.dropdown-toggle:active {
    .nav-link.dropdown-toggle:active {
        // color: #16181b;
        color:#212529; // same color as body color; default text
        background-color: $secondary;
    }
}

.nav__searchbar {
    // fixes ugly break of button when minimizing screen
    min-width: 35%;

    @include media-breakpoint-down(xs) {
        // always full width on mobile devices
        width: 100%;
    }
    &__input {
        // no height cause of flexible element height
        border: 1px solid #dee2e6;
        padding-left: 5px;
        border-right: 0;
        border-radius: 0.25rem 0 0 0.25rem;
        height: auto; // overrides form-control height: calc(...)
    }
    &__button {
        // no height cause of flexible element height
        width: 45px;
        border: 0;
        // border-radius: 0.25rem 0 0 0.25rem !important;
        // transform: scaleX(-1);
        border-radius: 0 0.25rem 0.25rem 0 !important;
    }
}

// Fix i-phone input problem
input, input:before, input:after {
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
}
