// _variables.scss

// override default bootstrap color
$primary: #3a7b00;
// override default bootstrap color
$secondary: #a6ce17;

// own color definitions
$tertiary: #2C5C00;
$quaternary: #337003;
$white: #fff;
$grey: #ccc;
$light-grey: #eee;
$black: #000;

$color-grey: $grey;
$color-darkgreen: #2C5C00;
$color-green: $primary;
$color-nearwhite: #fafafa;

// colors
// override default bootstrap body bg-color color
$body-bg: $white;
// override default bootstrap link color
$link-color: $black;
// override default bootstrap text color
$text-color: $black;

// text-{color}
$colors: (
    primary: $primary,
    secondary: $secondary,
    grey: $grey,
    light-grey: $light-grey,
    white: $white,
    black: $black,
);

// bg-{color}
$theme-colors: (
    primary: $primary,
    secondary: $secondary,
    grey: $grey,
    light-grey: $light-grey,
    white: $white,
    black: $black,
    tertiary: $tertiary,
    quaternary: $quaternary,
);

// border
$border-radius: 8px;
$border-color: $light-grey;

// override default bootstrap grid
$grid-columns: 12;
$grid-gutter-width: 16px;
$grid-gutter-margin: 12px;

/* override bootstrap breakpoints */
$grid-breakpoints: (
    xxxs: 0,
    xxs: 375px,
    xs: 568px,
    sm: 667px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1280px,
    xxxl: 1600px
);
$container-max-widths: (
    xxs: 375px,
    xs: 568px,
    sm: 667px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1280px,
    xxxl: 1600px
);

// font-sizes
$font-size-base: 1.0rem; // override bootstrap default font-size
$font-size-xxs : 0.725rem;
$font-size-xs  : 0.85rem;
$font-size-sm  : 0.9rem;
$font-size-md  : 1.0rem;
$font-size-lg  : 1.125rem;
$font-size-xl  : 1.25rem;
$font-size-xxl : 1.3755rem;
$font-size-xxxl : 1.5rem;

// font-size maps
$font-sizes: (
    "0-5":0.5rem,
    "0-6":0.6rem,
    "0-7":0.7rem,
    "0-8":0.8rem,
    "0-9":0.9rem,
    1    :1rem,
    "1-1":1.1rem,
    "1-2":1.2rem,
    "1-3":1.3rem,
    "1-4":1.4rem,
    "1-5":1.5rem,
    2    :2rem,
    3    :3rem,
    4    :4rem,
    5    :5rem,
);
$font-size-names: (
    "xxsmall": $font-size-xxs,
    "xsmall" : $font-size-xs,
    "small"  : $font-size-sm,
    "medium" : $font-size-md,
    "large"  : $font-size-lg,
    "xlarge" : $font-size-xl,
    "xxlarge": $font-size-xxl,
    "xxxlarge": $font-size-xxxl,
);
$font-sizes-breakpoints: (
    "xxxs":0.6rem,
    "xxs" :0.725rem,
    "xs"  :0.85rem,
    "sm"  :0.9rem,
    "md"  :1.0rem,
    "lg"  :1.125rem,
    "xl"  :1.25rem,
    "xxl" :1.375rem,
    "xxxl":1.5rem,
);

// $font-weights
$font-weights: (
    "lightest": 200,
    "lighter": 200,
    "light": 300,
    "regular": 400,
    "medium": 500,
    "bold": 700,
    "bolder": 800,
    "boldest": 900,
);

// navbar dropdown
// override bootstrap default primary color
$dropdown-link-active-bg: $secondary;

// input focus color
$component-active-bg: $secondary;

// override bootstrap default color on input focus
$input-focus-border-color: rgba($component-active-bg, 25%);

// override bootstrap default color input box-shadow
// $input-box-shadow: inset 0 1px 1px rgba($primary, .090) !default;
$input-btn-focus-width:  .2rem;
$input-btn-focus-color:  rgba($component-active-bg, .25);
$input-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;
