// _breadcrump.scss

// TODO: GET FROM _VARIABLES FILE
$breadcrump-font-size: 13px;
$breadcrump-font-color: #000;
$breadcrump-bg: #3a7b00;

// TODO: GET FROM _VARIABLES FILE
$breadcrump-bg-item-basic: #a6ce17;
$breadcrump-bg-item-active: #e6e6e6;

.basic-before__after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    content: " ";
}

.breadcrump {
    // height: 36px; replaced through bootstrap
    display: flex;
    list-style: none;
    overflow: hidden;
    // padding: 0; replaced through bootstrap
    // margin: 20px 0 0 0; replaced through bootstrap

    .breadcrump__item {
        margin-left: 0;
        line-height: 35px;
        list-style-type: none;
        padding: 0 5px 0 30px;
        border-right: 1px solid transparent;
        background-color: $breadcrump-bg-item-basic;
        position: relative;

        &::after {
            @extend .basic-before__after;
            z-index: 1;
            top: -12px;
            right: -21px;
            border-top: 30px solid transparent;
            border-left: 20px solid $breadcrump-bg-item-basic;
            border-bottom: 30px solid transparent;
        }

        &:first-child {
            padding-left: 8px;
            vertical-align: text-bottom;
        }

        &:nth-child(n+2)::before {
            @extend .basic-before__after;
            border-top: 30px solid transparent;
            border-left: 20px solid $breadcrump-bg;
            border-bottom: 30px solid transparent;
            top: -12px;
            left: 3px;
        }

        &-active {
            background-color: $breadcrump-bg-item-active;

            &:after {
                @extend .basic-before__after;
                top: -12px;
                left: -2px;
            }
        }
    }

    .breadcrump__item__icon {
        font-size: 24px !important;
        color: #fff;
        vertical-align: sub;
        width: unset !important;
        height: unset !important;
        top: 0 !important;
    }

    .breadcrump__item__link {
        font-size: $breadcrump-font-size;
        color: $breadcrump-font-color;
        text-decoration: none;
        display: inline-block;
    }

    // dont show on mobile devices
    @include media-breakpoint-between(xxxs, xs) {
        display: none;
    }
}
